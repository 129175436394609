import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

export const collapseTransition = trigger('openClose', [
  state('true', style({ height: '*', marginBottom: '*' })),
  state('false', style({ height: '0px', marginBottom: '0px' })),
  transition('false <=> true', animate('200ms ease-in-out')),
  transition('void <=> true', animate(0)),
]);

@Component({
  selector: 'rtr-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.less'],
  animations: [collapseTransition],
  standalone: true,
  imports: [NgTemplateOutlet],
})
export class CollapseComponent {
  @Input() public triggerTemplate: TemplateRef<any>;
  @Input() public canClose: boolean = true;
  @Input() public open: boolean;
  @Output() public toggleOpenClose: EventEmitter<void> = new EventEmitter();
}
