import { ChangeDetectorRef, Component, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fileToBase64, getImageExifData } from '@recruitee/common';
import { TranslatePipe } from '@recruitee/i18n';
import {
  InteractionEventDirective,
  IconButtonComponent,
  AvatarComponent,
  IconComponent,
  IconColorDirective,
} from '@recruitee/user-interface';

@Component({
  selector: 'rtr-avatar-upload',
  templateUrl: './avatar-upload.component.html',
  styleUrls: ['./avatar-upload.component.less'],
  standalone: true,
  imports: [
    IconColorDirective,
    IconComponent,
    AvatarComponent,
    IconButtonComponent,
    InteractionEventDirective,
    TranslatePipe,
  ],
})
export class AvatarUploadComponent implements ControlValueAccessor, OnDestroy {
  public value: string | null;
  public disabled: boolean = false;
  public onTouched: () => void;
  public exifStyles: object;

  private ngOnDestroy$ = new Subject<void>();
  private onChange: (base64: string | null) => void;

  constructor(
    private cdRef: ChangeDetectorRef,
    @Optional() @Self() private ngControl: NgControl,
  ) {
    ngControl.valueAccessor = this;
  }

  public ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  public onFileChange(files: FileList | null): void {
    if (!files || !files[0]) return;

    const file = files[0];
    forkJoin([fileToBase64(file), getImageExifData(file)])
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(([base64, exifData]) => {
        this.value = base64;
        this.onChange(base64);
        this.exifStyles = exifData.styles;
        this.cdRef.detectChanges();
      });
  }

  public onDelete(event: Event): void {
    event.stopImmediatePropagation();
    event.preventDefault();

    this.value = null;
    this.onChange(null);
    this.cdRef.detectChanges();
  }

  public registerOnChange(fn: (arg: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = () => {
      fn();

      // let control = this.ngControl.control;
      // while (control) {
      //   const statusChanges = <EventEmitter<string>>control.statusChanges;
      //   statusChanges.emit(control.status);
      //   control = control.parent;
      // }
    };
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(urlOrBase64: string): void {
    this.value = urlOrBase64;
    this.cdRef.detectChanges();
  }
}
