<div [class.disabled]="disabled" (mouseup)="onTouched()" class="avatar-container">
  @if (!value) {
    <div
      (click)="fileInput.click()"
      class="placeholder"
      layout-vertical
      layout-align-items-center
      layout-justify-center
    >
      <span text-bold> {{ 'rtr.connections.form.picture_placeholder' | translate }} </span>
      <rt-icon name="file-upload" margin-top-small-xx></rt-icon>
    </div>
  }
  @if (value) {
    <rt-avatar
      [imageStyles]="exifStyles"
      [type]="'image-with-container'"
      [size]="'large'"
      [shape]="'oval'"
      [imageSrc]="value"
    ></rt-avatar>
    <div class="actions">
      <rt-icon-button
        (click)="fileInput.click()"
        class="upload"
        margin-right-small-x
        variant="plain"
        size="small"
        shape="square"
        iconName="file-upload"
      >
      </rt-icon-button>
      <rt-icon-button
        (interaction)="onDelete($event)"
        interactionEvent
        class="delete"
        variant="plain"
        iconName="delete"
        shape="square"
        size="small"
      >
      </rt-icon-button>
    </div>
  }
</div>
<input
  (change)="onFileChange(fileInput.files)"
  #fileInput
  id="file-input"
  type="file"
  accept="image/*"
  hidden
/>
