import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AvatarUploadComponent } from './components/avatar-upload/avatar-upload.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { CollapseComponent } from './components/collapse/collapse.component';

const components = [CollapseComponent, BreadcrumbsComponent, AvatarUploadComponent];

@NgModule({
  imports: [CommonModule, RouterModule, ...components],
  exports: components,
})
export class SharedModule {}
